<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col space-y-8">
        <div class="flex flex-col space-y-4">
          <h2 class="font-bold text-2xl text-promy-blue-300 sm:text-base">
            Il s’agit d’une résidence...
          </h2>
          <div class="flex mt-8 space-x-4 items-baseline">
            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="flex flex-wrap gap-2">
                  <selectable-button
                    v-for="(residence, index) in residences"
                    :key="residence + index"
                    v-model="form.informations.residence"
                    :option-name="residence"
                  />
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-4">
          <h2 class="font-bold text-2xl text-promy-blue-300 sm:text-base ">
            D'après vous y a-t-il des projets de vente autour de votre bien ?
          </h2>
          <div class="flex mt-8 space-x-4 items-baseline">
            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="flex flex-wrap gap-2">
                  <selectable-button
                    v-for="(distance, index) in distances"
                    :key="distance + index"
                    v-model="form.informations.distance_projet_promoteur_autour"
                    :option-name="distance"
                  />
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-4 pb-4">
          <div class="font-bold text-2xl text-promy-blue-300 sm:text-base">
            Avez-vous des voisins intéressés par un projet de vente ?
          </div>
          <validation-provider
            class="flex flex-col mb-4"
            name="form.informations.has_neighbor_or_otherOwner"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flex gap-4">
              <pro-radio
                label="Oui"
                :value="form.informations.has_neighbor_or_otherOwner"
                @change="change($event)"
              >
                Oui
              </pro-radio>
              <pro-radio
                label="Non"
                :value="form.informations.has_neighbor_or_otherOwner"
                @change="change($event)"
              >
                Non
              </pro-radio>
            </div>
            <div class="w-full">
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </div>
          </validation-provider>
          <div
            class="flex flex-col space-y-2"
            v-if="form.informations.has_neighbor_or_otherOwner === 'Oui'"
          >
            <div
              class="flex flex-col space-y-2 relative"
              v-for="(item, index) in form.informations
                .neighbors_or_otherOwners"
              :key="'other-' + index"
            >
              <button
                v-if="form.informations.neighbors_or_otherOwners.length > 1"
                class="ml-auto mt-4 text-sm font-bold text-promy-red absolute right-0 top-0 z-10"
                @click="deleteNeighbor(index)"
              >
                Supprimer -
              </button>
              <NeighborForm :form="form" :item="item" />
              <div class="py-2">
                <hr
                  v-if="
                    form.informations.neighbors_or_otherOwners.length > 1 &&
                    index !==
                      form.informations.neighbors_or_otherOwners.length - 1
                  "
                  class="h-1 bg-gray-300 my-2"
                />
              </div>
            </div>
            <button
              @click="addNeighbor"
              class="ml-auto mt-4 text-sm font-bold text-promy-green-300"
            >
              Ajouter +
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #buttons> <Buttons :form="form" :goNext="invalid" /></template>
    <template #right-side>
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'
import NeighborForm from './components/NeighborForm.vue'

export default {
  components: {
    Buttons,
    Map,
    NeighborForm,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      required: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      residences: ['Principale', 'Secondaire', 'Autre'],
      distances: ['- 200 m', '- 500 m', '-1km', '-5km', '+5km', 'Je ne sais pas'],
    }
  },
  methods: {
    change(value) {
      this.form.informations.has_neighbor_or_otherOwner = value
    },
    addNeighbor() {
      this.form.informations.neighbors_or_otherOwners.push({
        nom: null,
        prenom: null,
        adresse: {
          label: null,
          long: null,
          lat: null,
          code_insee: null,
        },
      })
    },
    deleteNeighbor(index) {
      this.form.informations.neighbors_or_otherOwners.splice(index, 1)
    },
  },
}
</script>
