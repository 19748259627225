var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TunnelLayout',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('div',{staticClass:"flex flex-col space-y-8"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('h2',{staticClass:"font-bold text-2xl text-promy-blue-300 sm:text-base"},[_vm._v(" Il s’agit d’une résidence... ")]),_c('div',{staticClass:"flex mt-8 space-x-4 items-baseline"},[_c('div',[_c('validation-provider',{staticClass:"relative flex flex-col",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex flex-wrap gap-2"},_vm._l((_vm.residences),function(residence,index){return _c('selectable-button',{key:residence + index,attrs:{"option-name":residence},model:{value:(_vm.form.informations.residence),callback:function ($$v) {_vm.$set(_vm.form.informations, "residence", $$v)},expression:"form.informations.residence"}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"mt-1 text-xs text-promy-red-400"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"flex flex-col space-y-4"},[_c('h2',{staticClass:"font-bold text-2xl text-promy-blue-300 sm:text-base "},[_vm._v(" D'après vous y a-t-il des projets de vente autour de votre bien ? ")]),_c('div',{staticClass:"flex mt-8 space-x-4 items-baseline"},[_c('div',[_c('validation-provider',{staticClass:"relative flex flex-col",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex flex-wrap gap-2"},_vm._l((_vm.distances),function(distance,index){return _c('selectable-button',{key:distance + index,attrs:{"option-name":distance},model:{value:(_vm.form.informations.distance_projet_promoteur_autour),callback:function ($$v) {_vm.$set(_vm.form.informations, "distance_projet_promoteur_autour", $$v)},expression:"form.informations.distance_projet_promoteur_autour"}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"mt-1 text-xs text-promy-red-400"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"flex flex-col space-y-4 pb-4"},[_c('div',{staticClass:"font-bold text-2xl text-promy-blue-300 sm:text-base"},[_vm._v(" Avez-vous des voisins intéressés par un projet de vente ? ")]),_c('validation-provider',{staticClass:"flex flex-col mb-4",attrs:{"name":"form.informations.has_neighbor_or_otherOwner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex gap-4"},[_c('pro-radio',{attrs:{"label":"Oui","value":_vm.form.informations.has_neighbor_or_otherOwner},on:{"change":function($event){return _vm.change($event)}}},[_vm._v(" Oui ")]),_c('pro-radio',{attrs:{"label":"Non","value":_vm.form.informations.has_neighbor_or_otherOwner},on:{"change":function($event){return _vm.change($event)}}},[_vm._v(" Non ")])],1),_c('div',{staticClass:"w-full"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"mt-1 text-xs text-promy-red-400"},[_vm._v(_vm._s(errors[0]))])])]}}])}),(_vm.form.informations.has_neighbor_or_otherOwner === 'Oui')?_c('div',{staticClass:"flex flex-col space-y-2"},[_vm._l((_vm.form.informations
              .neighbors_or_otherOwners),function(item,index){return _c('div',{key:'other-' + index,staticClass:"flex flex-col space-y-2 relative"},[(_vm.form.informations.neighbors_or_otherOwners.length > 1)?_c('button',{staticClass:"ml-auto mt-4 text-sm font-bold text-promy-red absolute right-0 top-0 z-10",on:{"click":function($event){return _vm.deleteNeighbor(index)}}},[_vm._v(" Supprimer - ")]):_vm._e(),_c('NeighborForm',{attrs:{"form":_vm.form,"item":item}}),_c('div',{staticClass:"py-2"},[(
                  _vm.form.informations.neighbors_or_otherOwners.length > 1 &&
                  index !==
                    _vm.form.informations.neighbors_or_otherOwners.length - 1
                )?_c('hr',{staticClass:"h-1 bg-gray-300 my-2"}):_vm._e()])],1)}),_c('button',{staticClass:"ml-auto mt-4 text-sm font-bold text-promy-green-300",on:{"click":_vm.addNeighbor}},[_vm._v(" Ajouter + ")])],2):_vm._e()],1)])]},proxy:true},{key:"buttons",fn:function(){return [_c('Buttons',{attrs:{"form":_vm.form,"goNext":_vm.invalid}})]},proxy:true},{key:"right-side",fn:function(){return [_c('Map',{attrs:{"showBtnParcelle":false,"form":_vm.form,"cadastre":_vm.cadastre}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }